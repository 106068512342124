import { useSpring, useTransform } from 'framer-motion';
function useHoverTilt(options = {}) {
    const { rotation = 10 } = options;
    const offsetX = useSpring(0, {
        stiffness: 300
    });
    const offsetY = useSpring(0, {
        stiffness: 300
    });
    const rotateX = useTransform(offsetY, v => v * rotation);
    const rotateY = useTransform(offsetX, v => v * rotation);
    return {
        style: {
            rotateX,
            rotateY
        },
        onMouseEnter: event => {
            const [x, y] = calcOffset(event);
            offsetX.set(x);
            offsetY.set(y);
        },
        onMouseLeave: () => {
            offsetX.set(0);
            offsetY.set(0);
        },
        onMouseMove: event => {
            const [x, y] = calcOffset(event);
            offsetX.set(x);
            offsetY.set(y);
        }
    };
}
/** Calculate a value between -1 and 1 for x and y position. */
function calcOffset(event) {
    const el = event.currentTarget;
    const { height, width, x: elementX, y: elementY } = el.getBoundingClientRect();
    const centerX = width / 2;
    const centerY = height / 2;
    const mouseX = event.clientX - elementX;
    const mouseY = event.clientY - elementY;
    const x = mouseX - centerX;
    const y = (mouseY - centerY) * -1;
    const scaleX = x / (width / 2);
    const scaleY = y / (height / 2);
    return [scaleX, scaleY];
}
export default useHoverTilt;
